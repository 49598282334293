import styled from "styled-components";

export const ErrorFront = styled.div`
  color: #fa5e5b;
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: bold;
  letter-spacing: -0.2px;
  //padding-top: 4.3em;
  line-height: 16px;
  text-align: center;

 /* @media screen and (min-width: 568px)  and (max-width: 840px) and (orientation: landscape) {
    padding-top: 5.3em auto auto auto;
  }*/
`;