import styled from "styled-components";

export const ErrorFront = styled.p`
  color: ${props => (props.error ? "#FA5E5B" : "#000000")};
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: bold;
  letter-spacing: -0.2px;
  line-height: 20px;
`;
