import React, { useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha-enterprise";
import { UseFua } from "../../Context";
import { useMachineContext } from "../../Context/contextMachine";
import Container from "../../Styles/PasswordFormStyle/Container";
import ContainerHeader from "../ContainerHeader";
import PrincipalContainer from "../PrincipalContainer";
import Footer from "../Footer";
import MainContent from "../MainContent";
import OtpInput from "../OtpInput";
import { useHistory } from "react-router";
import {
  EFA002,
  consumers,
  timeOutCustomerFlow,
  referrerMatchAppAuth
} from "../../Constant/Constants";
import config from "../../config";
import TitleHeader from "../TitleHeader";
import { FormGroup } from "../../Styles/PasswordFormStyle/FormGroup";
import { ErrorFront } from "../../Styles/PasswordFormStyle/ErrorFront";
import { ContainerRecaptcha } from "../../Styles/PasswordFormStyle/ContainerRecaptcha";
import { customerFlow } from "../../Services/CustomerFlow";
import { getTrace } from "../../Utilities/httpUtils";
import { ButtonGroupPasswordForm } from "../CustomButtons/ButtonGroupPasswordForm/ButtonGroupPasswordForm"
import { HandlerPasswordForm } from "../../HandlerComponents/HandlerPasswordForm/HandlerPasswordForm"
import { AlertGoOut } from "../AlertGoOut/AlertGoOut"
import HeaderTemplate from "../HeaderTemplate/HeaderTemplate"
import logoHeader from "../../Images/headerIconBancolombia.svg";
import { back, contextSwitchHandlerBiocatch } from "../../Utilities/GeneralFunctions"
import {
  loadFormPasswordDatalayer,
  clickContinueFormPasswordDatalayer,
  loadFormPasswordDynamicKeyDatalayer,
  loadFormPasswordAppPersonDatalayer,
  clickContinueFormPasswordAppPersonDatalayer,
  clickContinueFormPasswordDynamicKeyDatalayer
} from "../../HandlerComponents/HandlerDatalayers/HandlerDatalayers"
import { ContainerElement } from "../../Styles/PasswordFormStyle/ContainerElement"
import { useInterval } from 'react-use';

window.recaptchaOptions = {
  useRecaptchaNet: true,
  removeOnMount: false,
};

export function PasswordForm() {
  const [machine, send] = useMachineContext();
  const { username, deviceType } = UseFua();
  const [error, setError] = useState("");
  const [activeButton, setActiveButton] = useState(false);
  const recaptchaRef = useRef();
  const [values, setvalues] = useState();
  const history = useHistory();
  const [captchaToken, setCaptchaToken] = useState(null);
  const isRecaptchaActive = config.recaptcha.RECAPTCHA_STATUS;
  const urlBack = machine.context.startUrl;
  const isMatch = [
    "sendCredentials.errorForm",
    "errorForm",
    "sendCredentials.errorPrepareDynamicKey",
    "sendCredentials.unknowErrror"
  ];
  const conditionReCaptchaToken = captchaToken !== null && activeButton;
  const isMatchRedirectMessage = [
    "sendCredentials.authFua",
    "sendCredentials.success",
    "sendCredentials.redirect",
  ];
  const [showPopUpGoOut, setShowPopUpGoOut] = useState(false);
  const prompt = machine.context.prompt

  useEffect(() => {
    contextSwitchHandlerBiocatch("PASSWORD_FORM")
    executeInitialDatalayers()
  }, []);

  useEffect(() => {
    if (isRecaptchaActive === "false") {
      handledSubmit("");
    } else {
      recaptchaRef.current.reset();
    }
  }, [isRecaptchaActive]);

  useInterval(() => {
    if (consumers.includes(machine.context.idClient)) {
      window.location.replace(machine.context.redirectUri + EFA002 + getTrace(machine.context));
    }
  }, timeOutCustomerFlow);

  const validateForm = () => {
    const password = values;
    send("VALIDATE", { username, password, captchaToken, deviceType });
    if (isRecaptchaActive === "true") {
      recaptchaRef.current.reset();
    }
    executeContinueFormDatalayers()
  };

  const handledSubmit = value => setCaptchaToken(value);

  useEffect(() => {
    const errorTag = document.getElementById("scrollTop");
    if (errorTag) {
      errorTag.scrollIntoView(true);
      window.scrollTo(0, 0);
    }
  }, []);

  useEffect(() => {
    const step = referrerMatchAppAuth
      ? "FORM_PASSWORD_03"
      : "FORM_PASSWORD_02";
    customerFlow(machine.context, "0002", step);
  }, []);

  const showPopUp = () => {
    setShowPopUpGoOut(true)
  };

  const executeInitialDatalayers = () => {
    if (referrerMatchAppAuth !== null) {
      loadFormPasswordAppPersonDatalayer()
    } else {
      prompt != null && prompt.includes("PN|CD") ? loadFormPasswordDynamicKeyDatalayer() : loadFormPasswordDatalayer()
    }
  }

  const executeContinueFormDatalayers = () => {
    if (referrerMatchAppAuth !== null) {
      clickContinueFormPasswordAppPersonDatalayer()
    } else {
      prompt != null && prompt.includes("PN|CD") ? clickContinueFormPasswordDynamicKeyDatalayer() : clickContinueFormPasswordDatalayer()
    }
  }

  return (
    <PrincipalContainer>
      <HeaderTemplate logoHeader={logoHeader} isV2={false} exitFunction={showPopUp} backFunction={back} marginBottom='45px' marginBottomMobile='45px' />
      {showPopUpGoOut && <AlertGoOut machine={machine} setShowPopUpGoOut={setShowPopUpGoOut} isButtonBack={false} isV2={false} />}
      <MainContent>
        <TitleHeader title="Clave principal" />
        {machine.matches("sendCredentials.error") &&
          (() => {
            history.push("/login" + urlBack.split("login")[1]);
          })()}
        <Container>
          <FormGroup id="formGroup">
            <ContainerHeader
              subTitle="Es la misma que usas en el cajero automático"
              icon
            />
            <ContainerElement>
              <OtpInput
                setvalues={setvalues}
                error={error}
                setError={setError}
                setActiveButton={setActiveButton}
                isRecaptchaActive={isRecaptchaActive}
              />
              {error && (
                <ErrorFront error={error} isRecaptchaActive={isRecaptchaActive}>
                  {error}
                </ErrorFront>
              )}
            </ContainerElement>
            {isRecaptchaActive === "true" && (
              <ContainerRecaptcha>
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={config.recaptcha.RECAPTCHA_KEY}
                  onChange={handledSubmit}
                />
              </ContainerRecaptcha>
            )}
            <ButtonGroupPasswordForm conditionReCaptchaToken={conditionReCaptchaToken} validateForm={validateForm} />
          </FormGroup>
          <HandlerPasswordForm machine={machine} isMatch={isMatch} history={history} isMatchRedirectMessage={isMatchRedirectMessage} />
        </Container>
      </MainContent>
      <Footer />
    </PrincipalContainer>
  );
}
