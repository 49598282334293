import React from "react";
import Select from 'react-select';
import { Label } from "../../Styles/AppAtHandFormStyle/Label"
import { selectCustomStyle } from "./SelectCustom"
import {ContainerElement} from  "../../Styles/AppAtHandFormStyle/ContainerElement"

export default function SelectApp({
    options,
    selectedOption,
    onChange,
}) {
    return (
        <ContainerElement>
            <Label>Tipo de documento</Label>
            <Select
                defaultValue={selectedOption}
                value={selectedOption}
                onChange={onChange}
                options={options}
                styles={selectCustomStyle}
                width="370px"
                isSearchable={false}
                theme={theme => ({
                    ...theme,
                    borderRadius: 5,
                    colors: {
                        ...theme.colors,
                        primary: '#FFFFFF',
                    },
                    border: '0px'
                })}
            />
        </ContainerElement>
    );
}
