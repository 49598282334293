import styled from "styled-components";

export const FormGroup = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  align-content: center;
  gap: 8px;
`;
