import ButtonTemplate from "../../ButtonTemplate/ButtonTemplate";
import {
    ButtonEnableStyle,
    ButtonDisableStyle,
    ButtonBackStyle,
} from "../../ButtonTemplate/ButtonTemplateStyle";
import { ButtonGroup } from "../../../Styles/AlphanumericScreen/ButtonGroup"
import { back } from "../../../Utilities/GeneralFunctions"

export function ButtonGroupAlphanumericScreen({ errors, isValid, captchaToken}) {
    const showPopUp = () => back()
    return (
        <ButtonGroup>
            <ButtonTemplate
                customStyle={ButtonBackStyle}
                type="button"
                title={"Volver"}
                fuctionButton={showPopUp}
            />
            <ButtonTemplate
                customStyle={
                    !errors.pass && isValid && captchaToken !== null
                        ? ButtonEnableStyle
                        : ButtonDisableStyle
                }
                type={"submit"}
                title={"Continuar"}
                disabled={!isValid || captchaToken === null}
            />
        </ButtonGroup>
    );
}