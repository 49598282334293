import React, { useState, useEffect } from "react";
import { useMachineContext } from "../../Context/contextMachine";
import { UseFua } from "../../Context";
import { useHistory } from "react-router-dom";
import Container from "../../Styles/DynamicKeyFirstFactor/Container";
import ContainerHeader from "../ContainerHeader";
import PrincipalContainer from "../PrincipalContainer";
import Footer from "../Footer";
import MainContent from "../MainContent";
import OtpInputDynamicKey from "../OtpInputDynamicKey";
import Modal from "../Modal";
import ErrorDynamicKey from "../ErrorDynamicKey"
import { EFA002 } from "../../Constant/Constants";
import { errorsDynamicKey, codeDynamicKeyIncorrect, timeExpirationDynamicKey, listCodeErroDynamicKey } from "../../Constant/ConstantsDynamicKey";
import { useIdleTimer } from "react-idle-timer";
import TitleHeader from "../TitleHeader";
import { FormGroup } from "../../Styles/DynamicKeyFirstFactor/FormGroup"
import { ErrorFront } from "../../Styles/DynamicKeyFirstFactor/ErrorFront"
import { customerFlow } from "../../Services/CustomerFlow";
import { getTrace } from '../../Utilities/httpUtils'
import { AlertGoOut } from "../AlertGoOut/AlertGoOut"
import { ButtonGroupDynamicKeyFirstFactor } from "../CustomButtons/ButtonGroupDynamicKeyFirstFactor/ButtonGroupDynamicKeyFirstFactor"
import { HandlerDynamicKeyFirstFactor } from "../../HandlerComponents/HandlerDynamicKeyFirstFactor/HandlerDynamicKeyFirstFactor"
import HeaderTemplate from "../HeaderTemplate/HeaderTemplate"
import logoHeader from "../../Images/headerIconBancolombia.svg";
import { contextSwitchHandlerBiocatch, back } from "../../Utilities/GeneralFunctions"
import { loadFormUserDynamicKeyFirstFactorDatalayer, clickValidateFormDynamicKeyFirstFactorDatalayer, pushDatalayerClientIdDynamicKeyFirstFactor } from "../../HandlerComponents/HandlerDatalayers/HandlerDatalayers"
import { ContainerElement } from "../../Styles/DynamicKeyFirstFactor/ContainerElement"

export function DynamicKeyFirstFactor() {
  const history = useHistory();
  const { username, deviceType } = UseFua();
  const [machine, send] = useMachineContext();
  const [error, setError] = useState("");
  const [activeButton, setActiveButton] = useState(false);
  const [values, setvalues] = useState();
  const [showAlert, setShowAlert] = useState(false);
  const [customMessage, setMessage] = useState("");
  const [customTitle, setTitle] = useState("");
  const [incorrectDynamicKey, setIncorrectDynamicKey] = useState(false)
  const isMatchErrorDynamicKey = ["validateDynamicKey.errorGeneric", "validateUserDynamicKey.generalError"]
  const [showPopUpGoOut, setShowPopUpGoOut] = useState(false);
  const isV2 = window.document.URL.includes("v2")

  useEffect(() => {
    contextSwitchHandlerBiocatch("DYNAMIC_KEY_FIRST_FACTOR_FORM")
    pushDatalayerClientIdDynamicKeyFirstFactor()
    loadFormUserDynamicKeyFirstFactorDatalayer()
  }, []);

  useEffect(() => {
    customerFlow(machine.context, "0001", "FORM_DYNAMIC_KEY_01")
  }, []);

  useEffect(() => {
    if (history.action === "PUSH") {
      send("RETRYAUTH");
    }
  }, [history]);

  useEffect(() => {
    if (listCodeErroDynamicKey.includes(machine.context.errorCode)) {
      if (codeDynamicKeyIncorrect.includes(machine.context.errorCode)) {
        setShowAlert(true)
      }
      errorsDynamicKey.forEach(element => {
        if (element.code === machine.context.errorCode) {
          setTitle(element.title)
          setMessage(element.description)
        }
      });
    }
  }, [machine]);

  const validateForm = async () => {
    clickValidateFormDynamicKeyFirstFactorDatalayer()
    send("RETRYAUTH", { values, captchaToken: "", username, deviceType });
    send("VALIDATEDYNAMICKEY", { values, captchaToken: "", username, deviceType });
  };

  const handleOnIdle = () => {
    window.location.replace(machine.context.redirectUri + EFA002 + getTrace(machine.context));
  };

  useIdleTimer({
    timeout: timeExpirationDynamicKey,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  const showPopUp = () => {
    setShowPopUpGoOut(true)
  };

  return (
    <PrincipalContainer>
      <HeaderTemplate logoHeader={logoHeader} isV2={isV2} exitFunction={showPopUp} backFunction={back} marginBottom='45px' marginBottomMobile='45px' />
      {showPopUpGoOut && <AlertGoOut machine={machine} setShowPopUpGoOut={setShowPopUpGoOut} isButtonBack={false} isV2={isV2} />}
      <MainContent>
        <TitleHeader title="Clave dinámica" />
        <Container>
          <ContainerHeader icon subTitle={"Genérala desde tu App Bancolombia o ingresa el código de seguridad que hemos enviado como SMS."} />
          <FormGroup id="formGroup">
            <ContainerElement>
              <OtpInputDynamicKey
                setvalues={setvalues}
                error={error}
                setError={setError}
                setActiveButton={setActiveButton}
                setIncorrectDynamicKey={setIncorrectDynamicKey}
                incorrectDynamicKey={incorrectDynamicKey}
              />
              {error && <ErrorFront error={error}>{error}</ErrorFront>}
            </ContainerElement>
            <ButtonGroupDynamicKeyFirstFactor machine={machine} activeButton={activeButton} validateForm={validateForm} ></ButtonGroupDynamicKeyFirstFactor>
          </FormGroup>
          {(isMatchErrorDynamicKey.some(machine.matches)) && (
            <Modal
              isShowing
              height="720px"
              topM="0%"
              top="0%"
              buttonClose={false}
              width="430px"
              widthM="340px"
            >
              <ErrorDynamicKey codeError={machine.context.errorCode} />
            </Modal>
          )}
        </Container>
        <HandlerDynamicKeyFirstFactor machine={machine} showAlert={showAlert} customMessage={customMessage} setShowAlert={setShowAlert} customTitle={customTitle} history={history} />
      </MainContent>
      <Footer />
    </PrincipalContainer>
  );
}
