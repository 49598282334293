import styled from "styled-components";


export const ButtonDisableStyle = `
  background: #D9DADD;
  border: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 184px;
  height: 50px;
  position: relative;
  border-radius: 100px;
  font-family: "CIBFont Sans";
  font-weight: 600;
  font-size: 16px;
  outline: none;
  padding: 12px 24px;
  gap: 8px;
  cursor: no-drop;
  color: #97999B;

  @media screen and (min-width: 280px) and (max-width: 480px) {
    width: 144px;
  }
`;

export const ButtonEnableStyle = `
  background: #FDDA24;
  border: 0;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 184px;
  height: 50px;
  position: relative;
  border-radius: 100px;
  font-family: "CIBFont Sans";
  font-weight: 600;
  font-size: 16px;
  outline: none;
  padding: 12px 24px;
  gap: 8px;
  color: #2C2A29;

  @media screen and (min-width: 280px) and (max-width: 480px) {
    width: 144px;
  }
`;

export const ButtonBackStyle = `
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  gap: 8px;
  width: 184px;
  height: 50px;
  border: 1px solid #2C2A29;
  border-radius: 100px;
  background: #FFFFFF;
  cursor: pointer;

  @media screen and (min-width: 280px) and (max-width: 480px) {
    width: 144px;
  }
`;

export const DefaultLabel = `
  width: 66px;
  height: 26px;
  justify-content: center;
  font-family: 'CIBFont Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  color: black;
  display: flex;
  align-items: center;
  text-align: center;`

  export const OnlyButtonDisableStyle = `
  background: #D9DADD;
  border: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 384px;
  height: 50px;
  position: relative;
  border-radius: 100px;
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 16px;
  outline: none;
  padding: 12px 24px;
  gap: 8px;
  cursor: no-drop;
  color: #97999B;

  @media screen and (min-width: 280px) and (max-width: 480px) {
    width: 336px;
  }
`;

export const OnlyButtonEnableStyle = `
  background: #FDDA24;
  border: 0;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 384px;
  height: 50px;
  position: relative;
  border-radius: 100px;
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 16px;
  outline: none;
  padding: 12px 24px;
  gap: 8px;
  color: #2C2A29;

  @media screen and (min-width: 280px) and (max-width: 480px) {
    width: 336px;
  }
`;

export const Label = styled.label`
   ${props => props.labelStyle || DefaultLabel}
`;

export const ButtonTemplateStyle = styled.button`
     ${props => props.customStyle }
`;