import styled from "styled-components";

export const Title = styled.div`
display: flex;
justify-content: center;
text-align: center;

font-family: "CIBFont Sans";
font-style: normal;
font-weight: 700;
font-size: 28px;

letter-spacing: -0.6px;
color: rgba(44, 42, 41, 1);
line-height: 32px;

@media screen and (min-width: 280px) and (max-width: 420px) {
  font-size: 22px;
}

@media screen and (min-width: 421px) and (max-width: 1280px) {
  font-size: 24px;
}
`;
