import styled from "styled-components";

export const Label = styled.label`
  display: flex;
  width: 100%;
  margin-top: 30px;

  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;

  letter-spacing: '-0.2px';
  color: #2C2A29;
  opacity: 0.8;
`;
