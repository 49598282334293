import React, { useState, useEffect } from "react";
import { useMachineContext } from "../Context/contextMachine";
import Container from "./Container";
import ContainerHeader from "./ContainerHeader";
import PrincipalContainer from "./PrincipalContainer";
import Footer from "./Footer";
import MainContent from "./MainContent";
import OtpInputDynamicKey from "./OtpInputDynamicKey";
import axios from "axios";
import AlertMessageDynamicKey from "./AlertMessageDynamicKey"
import config from "../config";
import { contentType, UNAVAILABILITY_CODES_ERROS } from "../Constant/Constants";
import { errorsDynamicKey, codeDynamicKeyIncorrect, timeExpirationDynamicKey } from "../Constant/ConstantsDynamicKey";
import { useIdleTimer } from "react-idle-timer";
import TitleHeader from "./TitleHeader";
import { FormGroup } from "../Styles/DynamicKeyFormStyle/FormGroup"
import { ErrorFront } from "../Styles/DynamicKeyFormStyle/ErrorFront"
import usePageLoadEffect from "../MyHooks/usePageLoadEffect"
import { customerFlow } from "../Services/CustomerFlow";
import { ButtonGroupDynamicKey } from "./CustomButtons/ButtonGroupDynamicKey/ButtonGroupDynamicKey"
import { AlertGoOut } from "./AlertGoOut/AlertGoOut"
import { HandlerDynamicKeyForm } from "../HandlerComponents/HandlerDynamicKeyForm/HandlerDynamicKeyForm"
import { useHistory } from "react-router";
import HeaderTemplate from "./HeaderTemplate/HeaderTemplate"
import logoHeader from "../Images/headerIconBancolombia.svg";
import { contextSwitchHandlerBiocatch, back } from "../Utilities/GeneralFunctions"
import { loadFormDynamicKeyDatalayer, clickValidateFormDynamicKeyDatalayer } from "../HandlerComponents/HandlerDatalayers/HandlerDatalayers"
import { ContainerElement } from "../Styles/DynamicKeyFormStyle/ContainerElement"

export function DynamicKeyForm() {
  const [machine] = useMachineContext();
  const [error, setError] = useState("");
  const [activeButton, setActiveButton] = useState(false);
  const [values, setvalues] = useState();
  const [showAlert, setShowAlert] = useState(false);
  const [showErrorDynamicKey, setShowErrorDynamicKey] = useState(false);
  const [codeError, setCode] = useState("");
  const [redirect, setRedirect] = useState(false);
  const [incorrectDynamicKey, setIncorrectDynamicKey] = useState(false)
  const [errorForm, setErrorForm] = useState(false);
  const URLVALIDATEDYNAMICKEY = config.url.URLVALIDATEDYNAMICKEY
  const [showPopUpGoOut, setShowPopUpGoOut] = useState(false);
  const history = useHistory();
  const isV2 = machine.context.startUrl.includes("v2")

  useEffect(() => {
    contextSwitchHandlerBiocatch("DYNAMIC_KEY_FORM")
    loadFormDynamicKeyDatalayer()
  }, []);

  useEffect(() => {
    customerFlow(machine.context, "0002", "FORM_DYNAMIC_KEY_03")
  }, []);

  const validateForm = async () => {
    setRedirect(true)
    clickValidateFormDynamicKeyDatalayer()
    const response = await validateDynamicKeyFunction(values)
    if (response.hasOwnProperty("errors") === false) {
      customerFlow(machine.context, "0003", "FORM_DYNAMIC_KEY_03");
      const urlRedirect = machine.context.trace !== null && machine.context.trace !== "" ? `${machine.context.urlCode}&trace=${machine.context.trace}` : machine.context.urlCode
      window.location.replace(urlRedirect);
    } else {
      await validateErrorForm(response)
    }
  };

  const validateErrorForm = async response => {
    setRedirect(false)
    setActiveButton(false)
    const codeResponseError = response.errors[0].code;
    if (codeDynamicKeyIncorrect.includes(codeResponseError)) {
      setShowAlert(true)
      setIncorrectDynamicKey(true)
    } else if (UNAVAILABILITY_CODES_ERROS.includes(codeResponseError)) {
      setErrorForm(true)
    } else {
      await setAttributesModalError(codeResponseError)
    }
  }

  const setAttributesModalError = async codeErrorResponse => {
    errorsDynamicKey.forEach(errorDynamic => {
      if (codeErrorResponse === errorDynamic.code) {
        setCode(errorDynamic.code)
        setShowErrorDynamicKey(true)
      }
    })
  }

  const validateDynamicKeyFunction = async otp => {
    const codeAuthorization = machine.context.urlCode.split("=")[1]
    const request = {
      code: codeAuthorization,
      channel: machine.context.idClient,
      otp: otp
    }
    return axios
      .post(URLVALIDATEDYNAMICKEY, request, { contentType })
      .then(response => response.data);
  };

  const close = () => {
    setShowAlert(false);
  };

  const handleOnIdle = () => {
    window.location.replace(machine.context.startUrl)
  };

  useIdleTimer({
    timeout: timeExpirationDynamicKey,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  usePageLoadEffect()

  const showPopUp = () => {
    setShowPopUpGoOut(true)
  };

  return (
    <PrincipalContainer>
      <HeaderTemplate logoHeader={logoHeader} isV2={isV2} exitFunction={showPopUp} backFunction={back} marginBottom='45px' marginBottomMobile='45px' />
      {showPopUpGoOut && <AlertGoOut machine={machine} setShowPopUpGoOut={setShowPopUpGoOut} isButtonBack={false} isV2={isV2} />}
      <MainContent>
        <TitleHeader title="Clave dinámica" />
        <Container>
          <ContainerHeader
            icon
            heightL="10%"
            subTitle={"Genérala desde tu App Bancolombia o ingresa el código de seguridad que hemos enviado como SMS."}
          />
          <FormGroup id="formGroup">
            <ContainerElement>
              <OtpInputDynamicKey
                setvalues={setvalues}
                error={error}
                setError={setError}
                setActiveButton={setActiveButton}
                setIncorrectDynamicKey={setIncorrectDynamicKey}
                incorrectDynamicKey={incorrectDynamicKey}
              />
              <ErrorFront error={error}>{error}</ErrorFront>
            </ContainerElement>
            <ButtonGroupDynamicKey machine={machine} activeButton={activeButton} validateForm={validateForm} />
          </FormGroup>
          <HandlerDynamicKeyForm machine={machine} errorForm={errorForm} showErrorDynamicKey={showErrorDynamicKey} codeError={codeError} redirect={redirect} history={history} />
        </Container>
        {showAlert && (
          <AlertMessageDynamicKey close={close} customMessage={"La Clave Dinámica es inválida. Inténtalo nuevamente."} />
        )}
      </MainContent>
      <Footer />
    </PrincipalContainer>
  );
}
