import styled from "styled-components";
import background from "../Images/trazo-desktop.svg";
import backgroundMobile from "../Images/trazo-mobile.svg";

const MainContent = styled.div`
  justify-content: flex-start;
  padding-top: 15vh;
  background-image: url(${background});
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 100%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  row-gap: 16px;

  @media screen and (max-width: 610px) {
    background-size: contain;
    background-image: url(${backgroundMobile});
  }

  @media screen and (max-width: 480px) and (max-height: 900px) {
    padding-top: 10vh;
  }
`;
export default MainContent;
