import styled from "styled-components";

export const Container = styled.div`
  box-sizing: border-box;
  border-radius: 16px;
  padding: 32px;
  width: 556px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;

  @media screen and (min-width: 280px) and (max-width: 766px) {
    width: auto;
    padding: 24px;
  }
`;
