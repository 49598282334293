import styled from "styled-components";

export const FormGroup = styled.form`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  gap: 16px;
`;
