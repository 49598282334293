import styled from "styled-components";

const Container = styled.div`
  box-sizing: border-box;
  background-color: #ffffff;
  border-radius: 16px;
  width: 556px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  padding: 24px;

  @media screen and (min-width: 280px) and (max-width: 480px) {
    width: auto;
  }
`;
export default Container;