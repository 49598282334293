export const selectCustomStyle = {
    input: provided => ({
        ...provided,
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '5px',
        position: "relative",
        display: "flex",
    }),

    indicatorSeparator: provided => ({
        ...provided,
        display: 'none',
      }),

    option: (provided, state) => ({
        ...provided,
        padding: 10,
        zIndex: '510',
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '15px',
        opacity: state.isSelected ? '0.5' : "0.9",
        color: '#2C2A29',
    }),

    control: styles => ({
        ...styles,
        fontWeight: 'bold',
        backgroundColor: 'white',
        height: "10px",
        border: '0px',
        borderBottom: '2px solid #2C2A29',
        position: "relative",
        display: "flex",
        width: "100%",
        borderRadius: "0px",
        fontFamily: 'Open Sans',
    }),

    container: styles => ({
        ...styles,
        zIndex: '1000',
        display: "flex",
        width: "100%",
        flexDirection: "column",
        position: "relative",
        top: "0px",
        padding: '0px',

        "@media only screen and (width: 414px) and (height: 896px)": {
            paddingBottom: "0px",
        },
    }),

}
