export const flowNameUser = "autenticacion usuario"
export const stepNameUser = "ingresar usuario"

export const stepEnterPassword = "ingresar clave"
export const eventSendPasswordForm = "SEND_PASSWORD_FUA"

export const eventEnterDynamicKey = "ENTER_DYNAMIC_KEY"
export const flowNameDynamicKey = "autenticacion clave dinamica"
export const stepNameDynamicKey = "ingresar clave dinamica"
export const eventSendDynamicKey = "SEND_DYNAMIC_KEY"

export const next = "continuar"
export const back = "regresar"
export const validate = "validar"
export const understood = "entendido"

export const flowNameDynamicKeyFirstFactor = "autenticacion clave dinamica primer factor"
export const eventEnterDynamicKeyFirstFactor = "ENTER_DYNAMIC_KEY_FIRST_FACTOR"
export const stepNameDynamicKeyFirstFactor = "ingresar clave dinamica"
export const eventSendDynamicKeyFirstFactor = "SEND_DYNAMIC_KEY_FIRST_FACTOR"

export const eventChooseAppPay = "CHOOSE_TYPE_AUTHENTICATION"
export const flowNameAuthOtp = "autenticacion datos personales y otp"
export const stepNameChooseAppPay = "elegir app para autenticarse"
export const eventSendDynamicKeyType = "SEND_DYNAMIC_KEY_TYPE"
export const elementAppPerson = "clientes app personas"
export const elementAppAtHand = "clientes app a la mano"
export const flowNameLoginScreen = "seleccion tipo de autenticacion"
export const elementFlowPersons = "clientes app personas"
export const elementFlowAlm = "clientes app a la mano"

export const eventEnterPersonalData = "ENTER_PERSONAL_DATA"
export const stepNameEnterPersonalData = "ingresar datos personales"
export const eventSendPersonalData = "SEND_PERSONAL_DATA"

export const eventEnterSecurityCode = "ENTER_SECURITY_CODE"
export const stepNameEnterSecurityCode = "ingresar codigo de seguridad"
export const eventSendSecurityCode = "SEND_SECURITY_CODE"

export const eventSendLonginError = "SEND_LOGIN_ERROR"
export const errorIncorrectData = "El usuario o la contraseña no son correctos. Intenta de nuevo"
export const errorSystem = "El sistema no se encuentra disponible"
export const errorInvalidData = "No pudimos validar tus datos"
export const errorInvalidDataDescription = "Intentalo nuevamente. Nuestro equipo esta trabajando para brindarte una mejor experiencia."
export const errorSystemDescription = "Intentalo nuevamente mas tarde. Nuestro equipo esta trabajando para brindarte una pronta solución."

export const eventUserBlock = "SEND_ACCESS_ERROR"
export const errorUserBlock = "acceso bloqueado"
export const eventEnterAccessError = "ENTER_ACCESS_ERROR"

export const eventSendSystemError = "SEND_SYSTEM_ERROR"
export const eventEnterSystemError = "ENTER_SYSTEM_ERROR"

export const flowNameUserDynamicKey = "usuario y clave dinamica"