
import styled from "styled-components";
import PropTypes from "prop-types";
import { Field } from "formik";

const filteredPropsInput = ({ className, valid, error, ...props }) => (
    <Field className={className} {...props} />
);
filteredPropsInput.propTypes = {
    className: PropTypes.string.isRequired,
};

const pixel = "1px"
const pixelValid = "2px"
const border = "solid"
const colorSelected = "#ffc20e"
const colorValid = "#2C2A29"
const colorError = "red"
const noColor = ""
const validFont = `font-weight: bold;`
const selectedFont = `font-weight: 400;`
const validBorder = `border-bottom: ${pixelValid} ${border} ${colorValid};${validFont}`
const errorBorder = `${pixel} ${border} ${colorError};`
const selectedBorder = `border-bottom: ${pixel} ${border} ${colorSelected};${selectedFont}`
const borderBottom = "border-bottom :"

export const InputContainer = styled.div`
width: 100%;
display: flex;
flex-direction: column;
position: relative;
`;
export const Label = styled.label`
color: #2C2A29;
font-family: "Open Sans";
font-style: normal;
font-weight: 400;
font-size: 15px;
line-height: 0px;
top: 25px;
left: 5px;
transition: all 0.2s ease;
position: absolute;
flex: none;
order: 1;
flex-grow: 0;
opacity: 0.8;

@media screen and (min-width: 320px) and (max-width: 840px) and (orientation: landscape) and (min-aspect-ratio: 13/9) {
  top: 30px;
}

@media screen and (width: 320px) and (height: 568px) {
  top: 20px;
  font-size: 13px;
}

@media only screen and (width: 375px) and (height: 667px) {
  font-size: 13px;
}

@media only screen and (width: 375px) and (height: 812px) {
  font-size: 13px;
  position-top: 20px;
}

@media only screen and (width: 414px) and (height: 736px) {
  font-size: 13px;
}

@media only screen and (width: 414px) and (height: 896px) {
  position-top: 20px;
}

@media only screen and (width: 844px) and (height: 390px) {
  position-top: 0px;
}
`;
export const StyledInput = styled(filteredPropsInput)`
border: 0;
border-radius: 0px !important;
color: #292929;
font-family: "Open Sans";
letter-spacing: -0.3px;
line-height: 24px;
border-bottom: 1px solid #808285;
background-color: transparent;
outline: none;
padding: 12px 3px 5px 10px;
font-size: 16px;
transition: all 0.2s ease;
position: relative;
z-index: 510;
font-weight: 400;

&:not(:placeholder-shown) + ${Label} {
  transform: translateY(-30px) translateX(-10px);
  z-index: 501;
  background: transparent;
  padding: 0 8px;
}

&:focus + ${Label} {
  transform: translateY(-30px) translateX(-10px);
  z-index: 501;
  background: transparent;
  padding: 0 8px;
}

&:focus{
  ${selectedBorder};
  ${props => {
    if (!props.valid) {
      return `${borderBottom} ${props.error ? errorBorder : noColor};`;
    }
  }}
}

${props => {
        if (props.valid) {
            return validBorder;
        }
        return `${borderBottom} ${props.error ? errorBorder : noColor};`;
}}

@media only screen and (width: 375px) and (height: 667px) {
  top: 10px;
  height: 15px;
  padding-top: 8px;
  margin-bottom: 8px;
}

@media only screen and (width: 375px) and (height: 812px) {
  padding-top: 3px;
  margin-bottom: 8px;
  margin-top: 8px;
}

@media only screen and (width: 414px) and (height: 736px) {
  top: 10px;
  height: 15px;
  padding-top: 8px;
  margin-bottom: 8px;
}

@media only screen and (width: 844px) and (height: 390px) {
  top: 0px;
  position-top: 0px;
}
`;
