import styled from "styled-components";

export const ErrorFront = styled.div`
  //position: absolute;
 // padding-top: 15.5em;
  color: #fa5e5b;
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: bold;
  letter-spacing: -0.2px;
  line-height: 20px;
`;
