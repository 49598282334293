import RedirectionMessage from "../../Components/RedirectionMessage";
import ModalErrorTemplate from "../../Components/ModalErrorTemplate/ModalErrorTemplate";
import errorImageScoreRecaptcha from "../../Images/errorImageScoreRecaptcha.svg";
import { tokenSecondFactorFormPath, unavailabilityV2, EFA011, EFA012 } from "../../Constant/Constants"
import { getTrace } from '../../Utilities/httpUtils'
import { replaceUrl, contextSwitchHandlerBiocatch } from "../../Utilities/GeneralFunctions"
import React, { useEffect } from "react";

export function HandlerAlphanumericScreen({ machine, history }) {
    const isMatchUnavailability = ["validateAlphanumericKeyAndTokenSf.errorService"]
    const isMatchRedirectMessage = ["validateAlphanumericKeyAndTokenSf.isMigrated", "validateAlphanumericKeyAndTokenSf.redirectToUrl", "validateAlphanumericKeyAndTokenSf.success", "validateAlphanumericKeyAndTokenSf.validateTokenSf", "validateAlphanumericKeyAndTokenSf.validateAuthAlphanumericKey",]

    useEffect(() => {
        contextSwitchHandlerBiocatch("ALPHANUMERIC_KEY_FORM")
    }, []);

    const redirectUri = async (event, efa) => {
        event.preventDefault();
        replaceUrl(machine.context.redirectUri + efa + getTrace(machine.context))
    };

    const backStart = () => {
        history.push("/login" + machine.context.startUrl.split("login")[1]);
    }

    useEffect(() => {
        { isMatchUnavailability.some(machine.matches) && (() => history.push(unavailabilityV2))() }
        { machine.matches("validateAlphanumericKeyAndTokenSf.validateAuthTokenSf") && history.push(tokenSecondFactorFormPath) }
    }, [machine]);

    return (
        <>
            {machine.matches("validateAlphanumericKeyAndTokenSf.errorIncorrectData") && (() => history.push("/login" + machine.context.startUrl.split("login")[1]))()}
            {machine.matches("validateAlphanumericKeyAndTokenSf.errorMaxAttemps") && (
                <ModalErrorTemplate
                    isShowCloseButton={false}
                    iconImageSvg={errorImageScoreRecaptcha}
                    startUrl={machine.context.startUrl}
                    title="Superaste el número de intentos"
                    description="Inténtalo más tarde"
                    functionButtonDescription={event => redirectUri(event, EFA012)}
                    buttonText="Entendido"
                    height={"320px"}
                    heightM={"315px"}
                    widthM={"400px"}
                    hiddenAditionalDescription={true}
                />
            )}
            {machine.matches("validateAlphanumericKeyAndTokenSf.errorScore") && (
                <ModalErrorTemplate
                    isShowCloseButton={false}
                    iconImageSvg={errorImageScoreRecaptcha}
                    startUrl={machine.context.startUrl}
                    title="No pudimos validar que eres tú"
                    description="Intentémoslo nuevamente."
                    functionButtonDescription={backStart}
                    buttonText="Regresar al sitio"
                    height={"320px"}
                    heightM={"315px"}
                    widthM={"400px"}
                    hiddenAditionalDescription={true}
                />
            )}
            {machine.matches("validateAlphanumericKeyAndTokenSf.errorKeyExceededLifetime") && (
                <ModalErrorTemplate
                    isShowCloseButton={false}
                    iconImageSvg={errorImageScoreRecaptcha}
                    startUrl={machine.context.startUrl}
                    title="Tu clave ha expirado"
                    description="Asegúrate de gestionar la activación de tus datos"
                    functionButtonDescription={event => redirectUri(event, EFA011)}
                    buttonText="Entendido"
                    height={"320px"}
                    heightM={"315px"}
                    widthM={"400px"}
                    hiddenAditionalDescription={true}
                />
            )}
            {(isMatchRedirectMessage.some(machine.matches)) && (<RedirectionMessage />)}
        </>
    )
}