import React from "react";
import PropTypes from "prop-types";
import { Title } from "../Styles/TitleHeaderStyle/Title";

function TitleHeader({ title }) {
  return (
    <>
      <Title>{title}</Title>
    </>
  );
}

TitleHeader.propTypes = {
  title: PropTypes.string.isRequired,
};

export default TitleHeader;
