import {createMachine, assign } from "xstate";

export const passwordDynamicKeyFieldMachine = createMachine(
  {
    id: "passwordField",
    initial: "idle",

    context: {
      activeFieldName: "field1DynamicKey",
      currentValue: "",
      countValue: 0,
      field1DynamicKey: {
        value: "",
        mask: "",
        nextField: "field2DynamicKey",
        previousField: "field1DynamicKey",
      },
      field2DynamicKey: {
        value: "",
        mask: "",
        nextField: "field3DynamicKey",
        previousField: "field1DynamicKey",
      },
      field3DynamicKey: {
        value: "",
        mask: "",
        nextField: "field4DynamicKey",
        previousField: "field2DynamicKey",
      },
      field4DynamicKey: {
        value: "",
        mask: "",
        nextField: "field5DynamicKey",
        previousField: "field3DynamicKey",
      },
      field5DynamicKey: {
        value: "",
        mask: "",
        nextField: "field6DynamicKey",
        previousField: "field4DynamicKey",
      },
      field6DynamicKey: {
        value: "",
        mask: "",
        nextField: "field6DynamicKey",
        previousField: "field5DynamicKey",
      },
    },
    states: {
      idle: {
        on: {
          CHANGE_ACTIVE_DYNAMIC_KEY: {
            target: "idle",
            actions: ["changeActiveDynamicKey"],
          },
          ADD_VALUE_DYNAMIC_KEY: {
            target: "idle",
            actions: ["addValueDynamicKey", "valueChangedDynamicKey", "moveField", "setError"],
          },
          REMOVE_VALUE_DYNAMIC_KEY: {
            target: "idle",
            actions: ["removeValueDynamicKey", "valueChangedDynamicKey", "setError"],
          },
          LAST_FIELD_DYNAMIC_KEY: {
            target: "idle",
            actions: ["lastFieldDynamicKey", "moveField"],
          },
          PASTE_DYNAMIC_KEY: {
            target: "idle",
            actions: ["pasteDynamicKey","valueChangedDynamicKey","moveField","setError"],
          },
        },
      },
    },
  },
  {
    actions: {
      changeActiveDynamicKey: assign((_, payload) => {
        const { fieldName } = payload;
        return {
          activeFieldName: fieldName,
        };
      }),
      moveField: () => {},
      setError: () => {},
      addValueDynamicKey: assign((context, payload) => {
        const { fieldName, value, mask } = payload;
        const currentValue = context[fieldName];
        const countValue = context.countValue;
        return {
          [fieldName]: {
            ...currentValue,
            value,
            mask,
          },
          activeFieldName: currentValue.nextField,
          countValue: countValue + 1,
        };
      }),
      removeValueDynamicKey: assign((context, payload) => {
        const { fieldName } = payload;
        const currentValue = context[fieldName];
        const countValue = context.countValue;
        return {
          [fieldName]: {
            ...currentValue,
            value: "",
            mask: "",
          },
          activeFieldName: currentValue.previousField,
          countValue: countValue - 1,
        };
      }),
      valueChangedDynamicKey: assign(ctx => {
        const { field1DynamicKey, field2DynamicKey, field3DynamicKey, field4DynamicKey, field5DynamicKey, field6DynamicKey } = ctx;
        return {
          currentValue: [field1DynamicKey, field2DynamicKey, field3DynamicKey, field4DynamicKey, field5DynamicKey, field6DynamicKey]
            .map(i => i.value)
            .join(""),
        };
      }),
      lastFieldDynamicKey: assign(ctx => {
        const { field1DynamicKey, field2DynamicKey, field3DynamicKey, field4DynamicKey, field5DynamicKey, field6DynamicKey } = ctx;
        const focusField = [field1DynamicKey, field2DynamicKey, field3DynamicKey, field4DynamicKey, field5DynamicKey, field6DynamicKey]
          .filter(e => e.value.length !== 0)
          .pop();
        return {
          activeFieldName:
            focusField === undefined ? "field1DynamicKey" : focusField.nextField,
        };
      }),
      pasteDynamicKey: assign((ctx, payload) => {
        let { fieldName, value, mask } = payload;
        const { countValue, field1DynamicKey, field2DynamicKey, field3DynamicKey, field4DynamicKey, field5DynamicKey, field6DynamicKey } = ctx;
        const fields = [field1DynamicKey, field2DynamicKey, field3DynamicKey, field4DynamicKey, field5DynamicKey, field6DynamicKey]
        let lasfield = fieldName;
        let countValueIncrement = 0;
        for (let i = countValue; i < fields.length; i++) {
          if (value.length === 0){break;} 
          fields[i].value = value.substring(0, 1);
          fields[i].mask = mask;
          value = value.substring(1);
          lasfield = fields[i].nextField ;
          countValueIncrement ++;
        }
        return {
          activeFieldName: lasfield,
          countValue: countValue + countValueIncrement,
        };
      }),
    },
  }
);

export const stateMachine = createMachine(passwordDynamicKeyFieldMachine);
