import React, { useRef, useMemo, useEffect } from "react";
import { useMachine } from "@xstate/react";
import { passwordDynamicKeyFieldMachine } from "../StateMachines/passwordDynamicKeyFieldMachine";
import { SIX, ONE, TOW, THREE, FOUR, FIVE } from "../Constant/Constants";
import PasswordInput from "./PasswordInput";
import {InputGroup} from "../Styles/OtpInputDynamicKeyStyle/InputGroup"


function OtpInputDynamicKey({ setvalues,  error, setError, setActiveButton, setIncorrectDynamicKey, incorrectDynamicKey, top, type, message }) {
  const keyCodeNumber = 8;
  const listFields = [ONE,TOW,THREE,FOUR,FIVE,SIX]
  const field1DynamicKeyRef = useRef();
  const field2DynamicKeyRef = useRef();
  const field3DynamicKeyRef = useRef();
  const field4DynamicKeyRef = useRef();
  const field5DynamicKeyRef = useRef();
  const field6DynamicKeyRef = useRef();
  const fieldMap = useMemo(
    () => ({
      field1DynamicKeyRef,
      field2DynamicKeyRef,
      field3DynamicKeyRef,
      field4DynamicKeyRef,
      field5DynamicKeyRef,
      field6DynamicKeyRef,
    }),
    []
  );

  const [machine, send] = useMachine(passwordDynamicKeyFieldMachine, {
    actions: {
      moveField: ctx => {
        const { activeFieldName } = ctx;
        const fieldName = `${activeFieldName}Ref`;
        const selectedField = fieldMap[fieldName];
        selectedField.current.focus();
        const userAgent = navigator.userAgent;
        if (userAgent.toLowerCase().includes("android")) {
          selectedField.current.setSelectionRange(ONE, ONE);
        }
      },
      setError: ctx => {
        const { countValue, currentValue } = ctx;
        if (countValue < SIX) {
          const messageError = type === "securityCode" ? "del código de seguridad" : "de la clave dinámica";
          const messageShown = message ? message : "Ingrese los 6 dígitos " + messageError;
          setError(messageShown);
          setActiveButton(false);
        } else {
          setError(undefined);
          setActiveButton(true);
          setvalues(currentValue);
        }
      },
    },
  });
  const { context } = machine;

  const onSelectHandle = () => {
    const userAgent = navigator.userAgent;
    if (userAgent.toLowerCase().includes("android")) {
     send("LAST_FIELD_DYNAMIC_KEY");
     return true;
    } 
    return false;
  };

  const handleClick = () => {
    send("LAST_FIELD_DYNAMIC_KEY");
  };

  const handleFocus = event => {
    const {
      target: { name },
    } = event;
    const fieldName = `${name}Ref`;
    const selectedField = fieldMap[fieldName];
    selectedField.current.setSelectionRange(ONE, ONE);
  };
  const handleBack = event => {
    const {
      target: { name },
      keyCode,
    } = event;
    const { previousField, value } = context[name];
    if (keyCode === keyCodeNumber && value === "") {
      const fieldName = `${previousField}Ref`;
      const selectedField = fieldMap[fieldName];
      selectedField.current.focus();
      const userAgent = navigator.userAgent;
      if (userAgent.toLowerCase().includes("android")) {
        selectedField.current.setSelectionRange(ONE, ONE);
      }
    }
  };

  const handleChange = event => {
    const {target: { name, value },} = event;
    const isEmpty = value.length === 0;
    const eventType = isEmpty ? "REMOVE_VALUE_DYNAMIC_KEY" : "ADD_VALUE_DYNAMIC_KEY";
    if (eventType === "ADD_VALUE_DYNAMIC_KEY") {
      if (!/[0-9]/.test(value)) {
        return;
      }
    }
    send(eventType, {
      fieldName: name,
      value,
      mask: "*",
    });
  };

  const handlePaste = event => {
    event.preventDefault();
    const {clipboardData, target: { name: fieldName}} = event;
    const pastedValue  = clipboardData.getData('Text');
    if (!pastedValue) return;
    if (!/^\d+$/.test(pastedValue)) return; 
    if (pastedValue.length === 0) return;
    send('PASTE_DYNAMIC_KEY', {
      fieldName,
      value: pastedValue,
      mask: "*",
    });
  };

  useEffect(() => {
    if(incorrectDynamicKey){
      context.activeFieldName = "field1DynamicKey"
      context.countValue = 0
      context.currentValue = ""
      setvalues("")
      setActiveButton(false)
      listFields.forEach(fieldNumber => {
        const nameKey = `field${fieldNumber}DynamicKey`
        context[nameKey].value = ""
        context[nameKey].mask = ""
      });

      setIncorrectDynamicKey(false)
    }
  }, [incorrectDynamicKey]);

  return (
    <InputGroup top={top}>
      <PasswordInput
        fieldRef={field1DynamicKeyRef}
        dataTestid="1"
        type="tel"
        inputMode="numeric"
        name="field1DynamicKey"
        error={error}
        maxLength={1}
        value={context.field1DynamicKey.mask}
        handleChange={handleChange}
        handleBack={handleBack}
        handleFocus={handleFocus}
        handleClick={handleClick}
        onSelectHandle={onSelectHandle}
        handlePaste={handlePaste}
      />
      <PasswordInput
        fieldRef={field2DynamicKeyRef}
        dataTestid="2"
        type="tel"
        inputMode="numeric"
        name="field2DynamicKey"
        error={error}
        maxLength={1}
        value={context.field2DynamicKey.mask}
        handleChange={handleChange}
        handleBack={handleBack}
        handleFocus={handleFocus}
        handleClick={handleClick}
        onSelectHandle={onSelectHandle}
        handlePaste={handlePaste}
      />
      <PasswordInput
        fieldRef={field3DynamicKeyRef}
        dataTestid="3"
        type="tel"
        inputMode="numeric"
        name="field3DynamicKey"
        error={error}
        maxLength={1}
        value={context.field3DynamicKey.mask}
        handleChange={handleChange}
        handleBack={handleBack}
        handleFocus={handleFocus}
        handleClick={handleClick}
        onSelectHandle={onSelectHandle}
        handlePaste={handlePaste}
      />
      <PasswordInput
        fieldRef={field4DynamicKeyRef}
        dataTestid="4"
        type="tel"
        inputMode="numeric"
        name="field4DynamicKey"
        error={error}
        maxLength={1}
        value={context.field4DynamicKey.mask}
        handleChange={handleChange}
        handleBack={handleBack}
        handleFocus={handleFocus}
        handleClick={handleClick}
        onSelectHandle={onSelectHandle}
        handlePaste={handlePaste}
      />
      <PasswordInput
        fieldRef={field5DynamicKeyRef}
        dataTestid="5"
        type="tel"
        inputMode="numeric"
        name="field5DynamicKey"
        error={error}
        maxLength={1}
        value={context.field5DynamicKey.mask}
        handleChange={handleChange}
        handleBack={handleBack}
        handleFocus={handleFocus}
        handleClick={handleClick}
        onSelectHandle={onSelectHandle}
        handlePaste={handlePaste}
      />
      <PasswordInput
        fieldRef={field6DynamicKeyRef}
        dataTestid="6"
        type="tel"
        inputMode="numeric"
        name="field6DynamicKey"
        error={error}
        maxLength={1}
        value={context.field6DynamicKey.mask}
        handleChange={handleChange}
        handleBack={handleBack}
        handleFocus={handleFocus}
        handleClick={handleClick}
        onSelectHandle={onSelectHandle}
        handlePaste={handlePaste}
      />
    </InputGroup>
  );
}
export default OtpInputDynamicKey;
