import styled from "styled-components";

export const ForgotUser = styled.p`
  text-align: end;
  width: 80%;
  cursor: pointer;
  color: #2c2a29;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
`;
