import ModalError from "../../Components/ModalError";
import errorImageScoreRecaptcha from "../../Images/errorImageScoreRecaptcha.svg";
import ModalErrorTemplate from "../../Components/ModalErrorTemplate/ModalErrorTemplate";
import { redirctFailedAttempts } from "../../Utilities/GeneralFunctions"
import { EFA005 } from "../../Constant/Constants";
import RedirectionMessage from "../../Components/RedirectionMessage";

export function HandlerSecurityCodeForm({ machine, timeExpire, title, message, isMatchRedirectMessage }) {
    const executeRedirctFailedAttempts = () => redirctFailedAttempts(machine)
    return (
        <div>
            {(machine.matches("validateOtp.errorAttemptsOvercome") || timeExpire) && (
                <ModalError isShowing topM="50%" top="20%" buttonClose={true} startUrl={machine.context.startUrl}
                    title={title}
                    message={message}
                    titleBotton="Regresar al sitio"
                />
            )}
            {(machine.matches("validateOtp.maxFrontendAttempts")) && (
                <ModalError isShowing topM="50%" top="20%" buttonClose={false} startUrl={machine.context.redirectUri + EFA005 + "&trace=" + machine.context.trace}
                    title={"Superaste el número de intentos"}
                    message={"Intenta de nuevo más tarde"}
                    titleBotton="Entendido"
                />
            )}
            {machine.matches("validateOtp.failedAttempts") && (
                <ModalErrorTemplate
                    isShowCloseButton={true}
                    iconImageSvg={errorImageScoreRecaptcha}
                    title={"Autenticación fallida"}
                    description={"Tuvimos problemas para realizar tu proceso de autenticación, por seguridad podrás continuar dentro de:"}
                    additionalDescription={machine.context.timeFailedAttempts}
                    height={"363px"}
                    heightM={"370px"}
                    buttonText={"Entendido"}
                    fuctionButtonClose={executeRedirctFailedAttempts}
                    functionButtonDescription={executeRedirctFailedAttempts}
                    hiddenAditionalDescription={false}
                />
            )}
            {machine.matches("validateOtp.errorScore") && (
                <ModalError isShowing topM="50%" top="20%" buttonClose={false} startUrl={machine.context.startUrl}
                    title="No pudimos validar que eres tú"
                    message="Intentémoslo nuevamente."
                    titleBotton="Regresar al sitio"
                />
            )}

            {(isMatchRedirectMessage.some(machine.matches)) && (<RedirectionMessage />)}
        </div>
    )
}